import { Plans } from 'src/app/enums/plans.enum';
import { Roles } from 'src/app/enums/roles.enum';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { BannerService } from '../banner/banner.service';
import { StripeResponse } from 'src/app/models/stripe-response.model';
import { LoadingService } from '../loading-service/loading.service';
import { catchError, tap } from 'rxjs/operators';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Observable, throwError } from 'rxjs';
import { StripeSubscriptionStatus } from 'src/app/enums/stripe-subscription-status.enum';
import { CheckSubscriptionResponse } from 'src/app/models/stripe-check-response.model';

@Injectable({
  providedIn: 'root',
})
export class StripeService {

  private apiUrl = environment.apiUrl;
  private publishableKey: Promise<Stripe | null>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private bannerService: BannerService,
    private loadingService: LoadingService) {
    // Initialize Stripe with your public key
    this.publishableKey = loadStripe(environment.stripePublicKey);
  }

  async redirectToCheckout_test(session: any) {
    window.location.href = session.url
    this.loadingService.hideSection();
  }

  async redirectToCheckout(sessionId: string) {
    const stripe = await this.publishableKey;
    if (stripe) {
      stripe.redirectToCheckout({ sessionId });
    } else {
      console.error('Stripe.js has not loaded');
    }
    this.loadingService.hideSection();
  }

  async createCheckoutSession(selectedPlan: Plans, selectedRole: Roles) {
    this.loadingService.showSection();
    // Call your backend to create a checkout session
    return this.http.post<any>(`${this.apiUrl}stripe/create-checkout-session`,
      { subscription_id: selectedPlan, role_id: selectedRole }).toPromise()
      .catch(error => {
        this.loadingService.hideSection();
        this.bannerService.showBanner('Error creating subscription. Please try again later.', 'error', true);
        console.error('Subscription upgrade or token refresh failed:', error);
      });
  }

  updateSubscription(subscriptionId: Plans) {
    this.loadingService.showSection();
    return this.http.post<StripeResponse>(`${this.apiUrl}stripe/update-subscription`, { subscription_id: subscriptionId }).toPromise()
      .then(response => {
        // Check if the response indicates success before refreshing the token
        if (response && response.subscriptionStatus === StripeSubscriptionStatus.Active) {
          this.bannerService.showBanner(response.message, 'success', true);
          // After upgrade is successful, refresh the token
          return this.authService.refreshToken().toPromise();
        } else {
          this.bannerService.showBanner(response.message, 'error', true);
        }
        this.loadingService.hideSection();
      })
      .then(newToken => {
        if (newToken) {
          this.loadingService.hideSection();
          this.router.navigate(['/subscription-updated']);
        }
      })
      .catch(error => {
        this.loadingService.hideSection();
        this.bannerService.showBanner('Error updating subscription. Please try again later.', 'error', true);
        // Log errors if the subscription upgrade or token refresh fails
        console.error('Subscription upgrade or token refresh failed:', error);
      });
  }

  cancelSubscription(subscriptionId: string) {
    this.loadingService.showSection();
    return this.http.post<StripeResponse>(`${this.apiUrl}stripe/cancel-subscription/${subscriptionId}`, {})
      .toPromise()
      .then(response => {
        debugger;
        // Check if the response indicates success before refreshing the token
        if (response && response.subscriptionStatus === StripeSubscriptionStatus.Canceled) {
          this.bannerService.showBanner(response.message, 'success', true);
          // After upgrade is successful, refresh the token
          return this.authService.refreshToken().toPromise();
        } else {
          this.bannerService.showBanner(response.message, 'error', true);
        }
        this.loadingService.hideSection();
      })
      .then(newToken => {
        if (newToken) {
          this.loadingService.hideSection();
          this.router.navigate(['/subscription-canceled']);
        }
      })
      .catch(error => {
        this.loadingService.hideSection();
        this.bannerService.showBanner('Error cancelling subscription. Please try again later.', 'error', true);
        console.error('Subscription upgrade or token refresh failed:', error);
      });
  }

  pauseSubscription(subscriptionId) {
    this.loadingService.showSection();
    return this.http.post<StripeResponse>(`${this.apiUrl}stripe/pause-subscription/${subscriptionId}`, {}).toPromise()
      .then(response => {
        // Check if the response indicates success before refreshing the token
        if (response && response.subscriptionStatus === StripeSubscriptionStatus.Paused) {
          this.bannerService.showBanner(response.message, 'success', true);
          // After upgrade is successful, refresh the token
          return this.authService.refreshToken().toPromise();
        } else {
          this.bannerService.showBanner(response.message, 'error', true);
        }
        this.loadingService.hideSection();
      })
      .then(newToken => {
        if (newToken) {
          this.loadingService.hideSection();
          this.router.navigate(['/subscription-paused']);
        }
      })
      .catch(error => {
        this.loadingService.hideSection();
        this.bannerService.showBanner('Error pausing subscription. Please try again later.', 'error', true);
        console.error('Subscription upgrade or token refresh failed:', error);
      });
  }

  resumeSubscription(subscriptionId: string) {
    this.loadingService.showSection();
    return this.http.post<StripeResponse>(`${this.apiUrl}stripe/resume-subscription/${subscriptionId}`, {}).toPromise()
      .then(response => {
        // Check if the response indicates success before refreshing the token
        if (response && response.subscriptionStatus === StripeSubscriptionStatus.Active) {
          this.bannerService.showBanner(response.message, 'success', true);
          // After upgrade is successful, refresh the token
          return this.authService.refreshToken().toPromise();
        } else {
          this.bannerService.showBanner(response.message, 'error', true);
        }
        this.loadingService.hideSection();
      })
      .then(newToken => {
        if (newToken) {
          this.loadingService.hideSection();
          this.router.navigate(['/subscription-resumed']);
        }
      })
      .catch(error => {
        this.loadingService.hideSection();
        this.bannerService.showBanner('Error resuming subscription. Please try again later.', 'error', true);
        console.error('Subscription upgrade or token refresh failed:', error);
      });
  }

  handlePaymentSuccess(sessionId: string): Promise<any> {
    this.loadingService.showSection();
    return this.http.post<StripeResponse>(`${this.apiUrl}stripe/payment-success`, { session_id: sessionId }).toPromise()
      .then(response => {
        // Handle successful response here
        if (response && response.subscriptionStatus === StripeSubscriptionStatus.Active) {
          this.bannerService.showBanner('Payment successful!', 'success', true);
          // You may want to refresh the token or navigate to a different route
          return this.authService.refreshToken().toPromise();
        } else {
          this.bannerService.showBanner('Payment failed: ' + response.message, 'error', true);
          throw new Error('Payment failed');
        }
      })
      .then(newToken => {
        if (newToken) {
          this.router.navigate(['/payment-success']);
        }
      })
      .catch(error => {
        this.bannerService.showBanner('Error processing payment. Please try again later.', 'error', true);
        console.error('Payment processing failed:', error);
      })
      .finally(() => {
        this.loadingService.hideSection();
      });
  }

  refreshTokenSuccess(): Observable<any> {
    return this.authService.refreshToken().pipe(
      tap(() => { }),
      catchError((error) => {
        // Handle error callback here
        return throwError(error);
      })
    );
  }

  checkSubscription(): Observable<CheckSubscriptionResponse> {
    return this.http.get<CheckSubscriptionResponse>(`${this.apiUrl}stripe/check-subscription`);
  }
}
