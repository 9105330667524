import { Component } from '@angular/core';
import { Roles } from 'src/app/enums/roles.enum';

@Component({
  selector: 'app-subscription-canceled',
  templateUrl: './subscription-canceled.component.html',
  styleUrl: './subscription-canceled.component.scss'
})
export class SubscriptionCanceledComponent {
  Roles = Roles;
}
