<section class="section cizeex-plus" id='cizeex-plus'>
    <div class="container">
        <div class="section-box">
            <div class="cizeex-plus-title" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>01.</span> {{"CizeexPlus.Title" | translate}}
                </h3>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" #imgContainer class='cizeex-plus-img-banner'>
                <img class='cizeex-plus-img rounded' [src]='"CizeexPlus.Img" | translate' alt="cizeex-plus-img">
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" class="cizeex-plus-description">
                <p class="mt-4" [innerHTML]='"CizeexPlus.Description" | translate'></p>
            </div>
            <div>
                <p data-aos="fade-up" data-aos-duration="1000"
                    *ngFor="let item of 'CizeexPlus.Description.Points'| translate; let i = index"
                    class="points-description" [innerHTML]="item | translate"></p>
            </div>
        </div>
    </div>
</section>