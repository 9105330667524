<section class="section" id="market">
        <div class="container">
                <div>
                        <app-loading *ngIf="!hasIndexData"></app-loading>
                        <div class="graph" *ngIf="data" data-aos="fade-up" data-aos-duration="1000">
                                <app-radar-chart [data]="data">
                                </app-radar-chart>
                        </div>
                        <app-loading *ngIf="!hasData"></app-loading>
                        <div class="graph" *ngIf="data && aiReaction">
                                <app-prompt-ui [isDisabled]="isPromptDisabled"
                                        [aiReaction]="aiReaction"></app-prompt-ui>
                        </div>
                </div>
        </div>
</section>