<section class="section" id="pricing">
  <div class="container pricing">
    <div class="section-box">
      <div class="cizeex-plus-title mb-5" data-aos="fade-up" data-aos-duration="1000">
        <h3 class="e-font section-title">
          <span class="code-font n-section-title">03.</span>
          {{ "PriceInfo.Title" | translate }}
        </h3>
      </div>
      <div class="pricing-tabs" data-aos="fade-up" data-aos-duration="1000">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" orientation="vertical" class="nav-tabs pricing-tabs">
          <li *ngFor="let pricingItem of 'PriceInfo.Pricing' | translate; let i = index" [title]="pricingItem.Tab"
            [ngbNavItem]="i">
            <a ngbNavLink>{{ pricingItem.Title }}</a>
            <ng-template ngbNavContent>
              <h4 class="title-tab-content">
                {{ pricingItem.Title }} |
                <span class="company-tab-content">{{ pricingItem.Tab }}</span>
              </h4>
              <h5 class="pricing-time">{{ pricingItem.Date }}</h5>
              <!-- {{pricingItem.Description | json}} -->
              <p *ngFor="
                  let pricingItemDescriptionParagraph of pricingItem.Description;
                  let i = index
                " class="pricing-description" [innerHTML]="pricingItemDescriptionParagraph | translate"></p>
              <span></span>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</section>