<div class="multiselect">
    <div class="cizeex-plus-title mb-5" *ngIf="itemTitle">
        <h3 class="e-font section-title">
            <span class="code-font n-section-title">▹</span>
            {{ itemTitle }}
        </h3>
    </div>
    <div class="multiselect-tabs">
        <ul ngbNav #nav="ngbNav" [(activeId)]="selectedItem" orientation="vertical" class="nav-tabs multiselect-tabs">
            <li *ngFor="let item of items; let i = index;" [title]="item.Tab" (click)="onItemClick(i + 1);"
                [ngbNavItem]="i + 1">
                <ng-container *ngIf="i !== skipIndex - 1">
                    <a ngbNavLink>{{ item.Title }}</a>
                    <!-- rest of your content -->
                    <ng-template ngbNavContent>
                        <h4 class="title-tab-content">
                            {{ item.Title }} |
                            <span class="company-tab-content">{{ item.Tab }}</span>
                        </h4>
                        <h5 class="multiselect-time">{{ item.Date }}</h5>
                        <!-- {{item.Description | json}} -->
                        <p *ngFor="
                        let ItemDescription of item.Description;
                        let i = index
                    " class="multiselect-description" [innerHTML]="ItemDescription | translate"></p>
                        <p class="multiselect-description" *ngIf="extraDescription" [innerHTML]="extraDescription"></p>
                        <span></span>
                    </ng-template>
                </ng-container>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>