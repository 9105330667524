import { Component, OnInit } from '@angular/core';
import { EconomicCalendarService } from 'src/app/services/economic-calendar/economic-calendar.service';
import { MarketReactionService } from 'src/app/services/market-reaction/market-reaction.service';
import { LoadingService } from 'src/app/services/loading-service/loading.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { EconomicEvent } from '../interface/market-event.model';

@Component({
  selector: 'app-economic-calendar',
  templateUrl: './economic-calendar.component.html',
  styleUrls: ['./economic-calendar.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [ // void => *
        style({ opacity: 0.5 }),
        animate('600ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class EconomicCalendarComponent implements OnInit {
  hasData: boolean = false;
  events: EconomicEvent[] = [];
  aiReaction: string | Promise<string>;

  groupedEvents = [];
  constructor(private marketReactionService: MarketReactionService,
    private calendarService: EconomicCalendarService,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.fetchEconomicCalendar("This Week", "united states");
  }

  onRowClicked(event: EconomicEvent): void {
    const dateObj = new Date(event.date);
    const year = dateObj.getFullYear();
    const month = dateObj.toLocaleString('en-US', { month: 'long' }); // Get month as a string
    const formattedString = `${year}-${month}`;
    this.loadingService.showSection();
    this.marketReactionService.getMarketReaction(event.event, event.date, event.forecast, event.previous).subscribe(
      response => {
        this.aiReaction = this.marketReactionService.convertAiToHtml(response.response.toString());
        this.loadingService.hideSection();
      },
      error => {
        console.error('Error fetching market reaction:', error);
        this.loadingService.hideSection();
      }
    );
  }

  fetchEconomicCalendar(selectedDateOption: string, selectedZone: string) {
    // Example usage to fetch economic calendar data
    this.hasData = false;
    this.loadingService.show();
    this.calendarService.getEconomicCalendar(selectedDateOption, selectedZone)
      .subscribe(
        data => {
          this.hasData = true;
          this.events = data;
          this.loadingService.hide();
        },
        error => {
          console.error('Error fetching economic calendar data:', error);
          this.loadingService.hide();
          // Handle error as needed
        }
      );
  }

  handleSelectionChange(selection:
    {
      dateOption: string,
      startDate: string,
      endDate: string,
      importance: string,
      zone: string
    }) {
    const { dateOption, startDate, endDate, importance, zone } = selection;
    // Call functions or perform actions based on the selection
    this.fetchDataBasedOnSelection(dateOption, startDate, endDate, importance, zone);
  }

  fetchDataBasedOnSelection(dateOption: string,
    startDate: string,
    endDate: string,
    importance: string,
    zone: string) {
    this.hasData = false;
    this.loadingService.show();
    this.calendarService.getOnSelectionEconomicCalendar(dateOption, startDate, endDate, importance, zone)
      .subscribe(
        data => {
          this.hasData = true;
          this.events = data;
          this.loadingService.hide();
        },
        error => {
          console.error('Error fetching economic calendar data:', error);
          this.loadingService.hide();
          // Handle error as needed
        }
      );
  }
}