<section class="section" id="plan">
  <div class="container">
    <div class="section-box" data-aos="flip-up" data-aos-duration="1000">
      <div class="cizeex-plus-title">
        <h3 class="e-font section-title">
          <span class="code-font n-section-title">05.</span>
          {{ "Header.Item6" | translate }}
        </h3>
      </div>
      <div class="plan-container">
        <!-- plan header -->
        <div class="plan-header">
          <h2>Select Your Plan</h2>
          <p>Pick Your Plan</p>
        </div>
        <app-multiselect [itemTitle]="'Plans'" [items]="'PriceInfo.Pricing' | translate" [selectedItem]="plan"
          [skipIndex]="skipPlan" (itemClicked)="onItemClicked($event)"></app-multiselect>
        <button (click)="onNext()" [disabled]="!this.selectedPlan">
          <a class="btn btn-1">
            <svg>
              <rect x="0" y="0" fill="none" width="100%" height="100%" />
            </svg>
            Continue to Subscription
          </a>
        </button>
        <p class="question-link">have an question? <a href="mailto:ccizeex.com@gmail.com" target="_blank">Click here</a>
        </p>
      </div>
    </div>
  </div>
</section>