import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EconomicCalendarComponent } from './economic-calendar/economic-calendar.component';
import { GeneralModule } from '../general/general.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MarketComponent } from './market.component';
import { NewsComponent } from './news/news.component';
import { StockNewsComponent } from './news/stock-news/stock-news.component';
import { EtfNewsComponent } from './news/etf-news/etf-news.component';
import { MarketNewsComponent } from './news/market-news/market-news.component';
import { GreedFearIndexComponent } from './greed-fear-index/greed-fear-index.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    EconomicCalendarComponent,
    MarketComponent,
    NewsComponent,
    StockNewsComponent,
    MarketNewsComponent,
    EtfNewsComponent,
    GreedFearIndexComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    GeneralModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: []
})
export class MarketModule { }
