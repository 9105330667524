import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from "src/app/services/language/language.service"
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'cizeex';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translateService: TranslateService,
    private location: Location,
    private languageService: LanguageService,
    private route: ActivatedRoute, private router: Router
  ) {
  }
  ngOnInit(): void {
    this.languageService.initLanguage();
    this.titleService.setTitle("Cizeex");

    this.metaService.addTags([
      { name: 'keywords', content: 'Insight. Growth. Together' },
      { name: 'description', content: 'Insight. Growth. Together.' },
    ]);
    AOS.init();
  }
}
