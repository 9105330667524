import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { BannerService } from '../services/banner/banner.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private bannerService: BannerService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      // Authorized, so return true
      return true;
    }
    // Not logged in, so redirect to login page
    this.bannerService.showBanner("You have no access", "warning", true );
    this.authService.currentUserValue(null);
    this.router.navigate(['/en'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
