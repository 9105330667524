export const environment = {
  production: false,
  staging: true,
  apiUrl: 'https://stg-api.cizeex.com/',
  //authApiUrl: 'https://auth.api.cizeex.com/',
  stripePublicKey: 'pk_test_51PockLCfeuZakdCowPC7Tr8Me8VYNyfGIaDvG0Dt08oN1o6HD4UAM0n3iMqOm5UIu6vqGTVZXkM2W75oG5b0gbV500uupu7U7J',
  priceTableId: 'prctbl_1PqUfXCfeuZakdCoTJFFPkzG',
  priceTableId_test: 'prctbl_1Pq52iCfeuZakdCoSMOk0rfD',
  trackAnalyticID: ""
};
