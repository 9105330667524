import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GreedFearIndex } from 'src/app/components/market/interface/greed-fear-index.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GreedFearIndexService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getGreedFearIndex(): Observable<GreedFearIndex> {
    return this.http.get<GreedFearIndex>(`${this.apiUrl}greed-fear-index`).pipe(
      catchError(error => {
        throw 'Error in retrieving economic calendar data: ' + error;
      })
    );
  }
}
