import { AfterViewInit, Component } from '@angular/core';
import { Roles } from 'src/app/enums/roles.enum';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-code-collaborate',
  templateUrl: './code-collaborate.component.html',
  styleUrl: './code-collaborate.component.scss'
})
export class CodeCollaborateComponent implements AfterViewInit {
  Roles = Roles;
  constructor(
    public analyticsService: AnalyticsService
  ) { }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
}
