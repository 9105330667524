import { AbstractControl, ValidatorFn } from '@angular/forms';

export function validEmailDomain(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const email = control.value;
    // Regular expression to check for a valid email format with a domain containing a period
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email) ? null : { 'invalidEmailDomain': true };
  };
}
