import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface BannerMessage {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  autoDismiss: boolean;
  dismissTime: number;
}

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  private bannerMessageSubject = new BehaviorSubject<BannerMessage | null>(null);
  bannerMessage$ = this.bannerMessageSubject.asObservable();

  showBanner(message: string, type: 'success' | 'error' | 'info' | 'warning' = 'info', autoDismiss: boolean = true, dismissTime: number = 5000) {
    this.bannerMessageSubject.next({ message, type, autoDismiss, dismissTime });

    if (autoDismiss) {
      setTimeout(() => this.clearBanner(), dismissTime);
    }
  }

  clearBanner() {
    this.bannerMessageSubject.next(null);
  }
}
