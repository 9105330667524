import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-cizeex-plus',
  templateUrl: './cizeex-plus.component.html',
  styleUrls: ['./cizeex-plus.component.scss']
})
export class CizeexPlusComponent implements OnInit {

  constructor(
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
  }

}
