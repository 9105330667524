<section class="section services" id='services'>
    <div class="container">
        <div class="section-box">
            <div data-aos="fade-up" data-aos-duration="1000">
                <h3 class="section-title">
                    <span class='n-section-title'>02.</span> {{"FeatureServices.Title" | translate}}
                </h3>
            </div>

            <div *ngFor='let service of "FeatureServices.Services" | translate; let i = index'
                class="d-flex proyect-container" data-aos="fade-up" data-aos-duration="1000">
                <owl-carousel-o class='owl-carousel-o' [options]="customOptions" *ngIf="i%2==0">
                    <ng-container *ngFor="let img of service.imgs">
                        <ng-template carouselSlide [id]="service.Title+slide+i">
                            <div #imgContainer class='img-container'>
                                <img class='img-feature-proyect rounded' [src]="img" [alt]="service.Title">
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
                <div class="img-feature-proyect-container" data-aos="fade-up" data-aos-duration="1000" *ngIf="i%2==0">
                    <div class="img-feature-proyect-box">
                        <div class="img-container" style='width: auto;'>
                            <img class='img-feature-proyect rounded' [src]="service.imgs[0]" [alt]="service.imgs[0]"
                                width='600px'>
                        </div>
                    </div>
                </div>
                <div [class.proyect-info-right]='i%2==0' [class.proyect-info-left]='i%2!=0' class="h-100"
                    style="background-image: url('{{service.img}}');">
                    <div class="proyect-box">
                        <h4 [class.text-right]='i%2==0' [class.text-left]='i%2!=0' class="feature-proyect">
                            {{"FeatureServices.Label" | translate}}</h4>
                        <h5 [class.text-right]='i%2==0' [class.text-left]='i%2!=0' class="proyect-name">
                            {{service.Title}}</h5>

                        <div class="proyect-description-box" (click)='debug()'>
                            <p class="mb-0" [innerHTML]="service.Description">
                            </p>
                        </div>
                        <ul [class.justify-content-end]='i%2==0' [class.justify-content-start]='i%2!=0'
                            class="proyect-skills">
                            <li *ngFor='let technology of service.Technologies' class="ml-3">
                                <span class="underline technology">{{technology}}</span>
                            </li>
                        </ul>
                        <div [class.text-right]='i%2==0' [class.text-left]='i%2!=0' class="proyect-links">
                            <a *ngIf="service['ghLink']" style='color: inherit' [href]="service['ghLink']"
                                target="_blank"><i class="ml-3 fab fa-github"></i></a>
                            <a *ngIf="service['demoLink']" style='color: inherit' [href]="service['demoLink']"
                                target="_blank"><i class="ml-3 fas fa-external-link-alt"></i></a>
                        </div>
                    </div>
                </div>
                <owl-carousel-o class='owl-carousel-o' [options]="customOptions" *ngIf="i%2!=0">
                    <ng-container *ngFor="let img of service.imgs">
                        <ng-template carouselSlide [id]="service.Title+slide+i">
                            <div class='img-container'>
                                <img class='img-feature-proyect rounded' [src]="img" [alt]="service.Title">
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
                <div class="img-feature-proyect-container" data-aos="fade-up" data-aos-duration="1000" *ngIf="i%2!=0">
                    <div class="img-feature-proyect-box">
                        <div class="img-container" style='width: auto;'>
                            <img class='img-feature-proyect rounded' [src]="service.imgs[0]" [alt]="service.imgs[0]"
                                width='600px'>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    </div>
</section>