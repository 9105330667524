import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
  @Input() tabsInfo: any[] = [];
  @Input() contentComponent: any;
  @Output() tabSelected = new EventEmitter<number>();

  active: number = 0;

  onTabClick(index: number) {
    this.active = index;
    this.tabSelected.emit(index);
  }
}
