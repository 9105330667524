import { Component } from '@angular/core';
import { EconomicCalendarComponent } from '../economic-calendar/economic-calendar.component';
import { StockNewsComponent } from './stock-news/stock-news.component';
import { EtfNewsComponent } from './etf-news/etf-news.component';
import { MarketNewsComponent } from './market-news/market-news.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss'
})
export class NewsComponent {
  active = 0

  contentComponent: any = MarketNewsComponent;

  onTabSelected(index: number) {
    switch (index) {
      case 0:
        this.contentComponent = MarketNewsComponent;
        console.log('Selected tab index is 0');
        // Add your logic for tab index 0
        break;
      case 1:
        this.contentComponent = StockNewsComponent;
        console.log('Selected tab index is 1');
        // Add your logic for tab index 1
        break;
      case 2:
        this.contentComponent = EtfNewsComponent;
        console.log('Selected tab index is 2');
        // Add your logic for tab index 2
        break;
      default:
        console.log('Selected tab index:', index);
        // Default case or additional cases can be added as needed
        break;
    }
  }

}
