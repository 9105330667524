import { Component, EventEmitter, Output } from '@angular/core';
import { EconomicCalendarService } from 'src/app/services/economic-calendar/economic-calendar.service';

@Component({
  selector: 'app-dropdowns',
  templateUrl: './dropdowns.component.html',
  styleUrl: './dropdowns.component.scss'
})
export class DropdownsComponent {
  @Output() selectionChanged =
    new EventEmitter<
      {
        dateOption: string,
        startDate: string,
        endDate: string,
        importance: string,
        zone: string
      }>();

  selectedDateOption: string = 'This Week';
  startDate: string = '';
  endDate: string = '';
  selectedImportance: string = 'None';
  selectedZone: string = 'united states';
  showError: boolean;
  errorMessage: string;

  constructor(private calendarService: EconomicCalendarService) { }

  // Action for Date dropdown selection
  onDateSelection() {
    if (this.selectedDateOption === 'Custom') {
      // Regex for YYYY-MM-DD format
      const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

      if (!this.startDate || !this.endDate) {
        this.showError = true;
        this.errorMessage = 'Both start and end dates are required';
      } else if (!dateRegex.test(this.startDate) || !dateRegex.test(this.endDate)) {
        this.showError = true;
        this.errorMessage = 'Invalid date format. Please use YYYY-MM-DD';
      } else {
        // Parse dates
        const [startYear, startMonth, startDay] = this.startDate.split('-').map(Number);
        const [endYear, endMonth, endDay] = this.endDate.split('-').map(Number);

        const startDateObj = new Date(startYear, startMonth - 1, startDay);
        const endDateObj = new Date(endYear, endMonth - 1, endDay);

        // Check if the dates are valid (e.g., not 2020-02-30)
        const isValidStartDate = startDateObj.getFullYear() === startYear && startDateObj.getMonth() === startMonth - 1 && startDateObj.getDate() === startDay;
        const isValidEndDate = endDateObj.getFullYear() === endYear && endDateObj.getMonth() === endMonth - 1 && endDateObj.getDate() === endDay;

        if (!isValidStartDate || !isValidEndDate) {
          this.showError = true;
          this.errorMessage = 'Invalid date value. Please check the day, month, and year.';
        } else if (startDateObj > endDateObj) {
          this.showError = true;
          this.errorMessage = 'Start date cannot be after end date';
        } else {
          this.showError = false;
          this.selectionChanged.emit(
            {
              dateOption: this.selectedDateOption,
              startDate: this.startDate,
              endDate: this.endDate,
              importance: this.selectedImportance,
              zone: this.selectedZone
            });
        }
      }
    }
    else {
      this.selectionChanged.emit(
        {
          dateOption: this.selectedDateOption,
          startDate: this.startDate,
          endDate: this.endDate,
          importance: this.selectedImportance,
          zone: this.selectedZone
        });
    }
  }
}
