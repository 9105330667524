<footer>
    <div [@animateFooter] class="display-none">
        <ul class="footer-left-bar d-none d-md-block">
            <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_github", "footer", "github")'
                    href="https://github.com/orgs/cizeex/dashboard" target='_blank'>
                    <i class="fab fa-github"></i>
                </a>
            </li>
            <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_linkedin", "footer", "github")'
                    href="https://www.linkedin.com/in/cizeex/" target='_blank'>
                    <i class="fab fa-linkedin-in"></i>
                </a>
            </li>
        </ul>
        <div class="footer-right-bar d-none d-md-block">
            <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "footer", "email")'
                href="mailto:ccizeex@gmail.com" target='_blank'>ccizeex&#64;gmail.com </a>
        </div>
    </div>

    <div class="footer-credits text-center">
        <p>&copy; 2024 Cizeex. All rights reserved.</p>
        <nav>
            <ul class="footer-nav">
                <li><a routerLink="/terms-of-service">Terms of Service</a></li>
                <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                <li><a href="/contact">Contact Us</a></li>
            </ul>
        </nav>
        <p class="magic-color">Credit for Jose Andres and Brittany Chiang for free angular/UX site</p>
    </div>
</footer>