import { Component } from '@angular/core';
import { Roles } from 'src/app/enums/roles.enum';

@Component({
  selector: 'app-subscription-resumed',
  templateUrl: './subscription-resumed.component.html',
  styleUrl: './subscription-resumed.component.scss'
})
export class SubscriptionResumedComponent {
  Roles = Roles;
}
