import { Component } from '@angular/core';
import { LoadingService } from 'src/app/services/loading-service/loading.service';
import { NewsService } from 'src/app/services/news-service/news.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { MarketReactionService } from 'src/app/services/market-reaction/market-reaction.service';
import { NewsEvent } from '../../interface/news-event.model';

@Component({
  selector: 'app-etf-news',
  templateUrl: './etf-news.component.html',
  styleUrl: './etf-news.component.scss',
  animations: [
    trigger('fade', [
      transition(':enter', [ // void => *
        style({ opacity: 0.5 }),
        animate('600ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class EtfNewsComponent {
  events: NewsEvent[] = [];
  hasData: boolean = false;
  aiReaction: string | Promise<string>;
  constructor(
    private marketReactionService: MarketReactionService,
    private newsService: NewsService,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.fetchNew();
  }
  fetchNew() {
    this.hasData = false;
    this.loadingService.show();
    this.newsService.getEtfNews()
      .subscribe(
        data => {
          this.hasData = true;
          this.events = data;
          this.loadingService.hide();
        },
        error => {
          console.error('Error fetching news data:', error);
          this.loadingService.hide();
        }
      );
  }
  onRowClicked(event: NewsEvent) {
    this.loadingService.showSection();
    this.marketReactionService.getNewsReaction(event.title, event.link, event.time, event.stock, event.resource).subscribe(
      response => {
        const formattedResponse = this.marketReactionService.convertAiToHtml(response.response.toString());
        this.aiReaction = formattedResponse;
        this.loadingService.hideSection();
      },
      error => {
        console.error('Error fetching market reaction:', error);
        this.loadingService.hideSection();
      }
    );
  }
}
