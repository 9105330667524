<div class="dropdown-container-margin">
  <!-- Dropdown 1: Date -->
  <div class="dropdown">
    <label>Date:</label>
    <select [(ngModel)]="selectedDateOption" (change)="selectedDateOption !== 'Custom' && onDateSelection()">
      <option value="Recent">Recent</option>
      <option value="Today">Today</option>
      <option value="Tomorrow">Tomorrow</option>
      <option value="This Week">This Week</option>
      <option value="Next Week">Next Week</option>
      <option value="This Month">This Month</option>
      <option value="Next Month">Next Month</option>
      <option value="Yesterday">Yesterday</option>
      <option value="Previous Week">Previous Week</option>
      <option value="Previous Month">Previous Month</option>
      <option value="Custom">Custom</option>
    </select>
    <div *ngIf="selectedDateOption === 'Custom'">
      <label class="ul-pointer">Start Date:</label>
      <input type="date" [(ngModel)]="startDate">
      <label class="ul-pointer">End Date:</label>
      <input type="date" [(ngModel)]="endDate" (change)="onDateSelection()">
    </div>
  </div>

  <!-- Dropdown 2: Importance -->
  <div class="dropdown">
    <label>Importance:</label>
    <select [(ngModel)]="selectedImportance" (change)="onDateSelection()">
      <option value="None">All</option>
      <option value="High">High</option>
      <option value="Medium">Medium</option>
      <option value="Low">Low</option>
    </select>
  </div>

  <!-- Dropdown 3: Zone -->
  <div class="dropdown">
    <label>Zone:</label>
    <select [(ngModel)]="selectedZone" (change)="onDateSelection()">
      <!-- Replace with your actual list of countries -->
      <option value="united states">United States</option>
      <!-- Add more options as needed -->
    </select>
  </div>
</div>
<div *ngIf="showError" class="ul-pointer">
  {{ errorMessage }}
</div>