import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrl: './multiselect.component.scss'
})
export class MultiselectComponent {
  @Input() skipIndex: number;
  @Input() items: any[] = [];
  @Input() itemTitle: string;
  @Input() extraDescription: string;
  @Input() selectedItem: number = 1;
  @Output() itemClicked = new EventEmitter<any>();

  onItemClick(event: any) {
    this.itemClicked.emit(event);
  }
}
