import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { format, parseISO } from 'date-fns';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EconomicCalendarService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getEconomicCalendar(option: string, country: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('option', option);
    params = params.append('country', country);

    return this.http.get<any>(`${this.baseUrl}economic_calendar`, { params }).pipe(
      catchError(error => {
        throw 'Error in retrieving economic calendar data: ' + error;
      })
    );
  }

  getOnSelectionEconomicCalendar(dateOption: string, startDate: string, endDate: string, importance: string, zone: string): Observable<any> {
    let params = new HttpParams();
    if (dateOption === 'Custom') {
      const parseStartDate = parseISO(startDate);
      const formattedStartDate = format(parseStartDate, 'dd/MM/yyyy');
      const parseEndDate = parseISO(endDate);
      const formattedEndDate = format(parseEndDate, 'dd/MM/yyyy');
      params = params.append('start_date', formattedStartDate);
      params = params.append('end_date', formattedEndDate);
    }
    params = params.append('option', dateOption);
    params = params.append('importance', importance);
    params = params.append('country', zone);

    return this.http.get<any>(`${this.baseUrl}/economic_calendar`, { params }).pipe(
      catchError(error => {
        throw 'Error in retrieving economic calendar data: ' + error;
      })
    );
  }
}