import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Plans } from 'src/app/enums/plans.enum';
import { StripeSubscriptionStatus } from 'src/app/enums/stripe-subscription-status.enum';
import { ManageSubscription } from 'src/app/enums/subscription-desc.enum';
import { CheckSubscriptionResponse } from 'src/app/models/stripe-check-response.model';
import { Stripe } from 'src/app/models/stripe.model';
import { BannerService } from 'src/app/services/banner/banner.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss']
})
export class PaymentInformationComponent implements OnInit {
  // Inputs and Outputs
  @Input() selectedPlan: Plans;
  @Input() planDesc: number = ManageSubscription.Upgrade;

  @Output() completeRegistration = new EventEmitter<any>();
  @Output() startOver = new EventEmitter<any>();

  // Form and UI state
  paymentForm: FormGroup;
  showPaymentMethod: boolean = true;
  extraDescription: string;

  // Subscription details
  selectedSubscription: string = ManageSubscription[this.planDesc];
  currentPlanId: string;
  currentPlanName: string;
  stripeSubscriptionId: string;
  hasSubscription: boolean;
  currentStripeSubscriptionStatus: string;

  // Button state
  buttonName: string = 'Checkout';
  disabledButton: boolean;

  // Manage subscription and Stripe status
  manageSubscription = ManageSubscription;
  stripeSubscriptionStatus = StripeSubscriptionStatus;

  constructor(private formBuilder: FormBuilder,
    private stripeService: StripeService,
    private bannerService: BannerService,
    private router: Router) {
    this.paymentForm = this.formBuilder.group({
      paymentMethod: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.checkSubscription();
    this.disabledButton = this.paymentForm.invalid;
    this.extraDescription = `New plan selected: <span class="underline">${Plans[this.selectedPlan]}</span>.`;
  }

  onItemClicked(value: number): void {
    this.selectedSubscription = ManageSubscription[value];

    if (ManageSubscription.Upgrade === value as ManageSubscription) {
      this.showPaymentMethod = true;
      this.buttonName = this.hasSubscription ? 'Modify Subscription' : 'Checkout'
    }
    else {
      this.showPaymentMethod = false;
      this.buttonName = 'Modify Subscription';
    }
  }

  onComplete(): void {
    if (this.paymentForm.invalid && this.selectedSubscription == ManageSubscription[ManageSubscription.Upgrade]) {
      this.bannerService.showBanner("You have no access", "error", true);
      this.router.navigate(['/home']);
    }
    this.completeRegistration.emit({
      selectedSubscription: ManageSubscription[this.selectedSubscription],
      paymentForm: this.paymentForm.value,
      hasSubscription: this.hasSubscription
    });
  }

  onStartOver(): void {
    this.startOver.emit();
  }

  checkSubscription(): void {
    this.stripeService.checkSubscription().subscribe((response: CheckSubscriptionResponse) => {
      if (response.hasSubscription) {
        this.hasSubscription = response.hasSubscription;
        this.currentPlanId = response.subscriptionId ?? '';
        this.currentPlanName = response.subscriptionPlan ?? '';
        this.stripeSubscriptionId = response.stripeSubscriptionId ?? '';
        this.currentStripeSubscriptionStatus = response.subscriptionStatus ?? '';
        this.extraDescription += ` Current Plan is <span class="underline">${this.currentPlanName}</span>
         <span class="underline">(${this.getEnumNameByValue(this.currentStripeSubscriptionStatus)})</span>.`;
      } else {
        this.hasSubscription = false;
      }
    });
  }

  getEnumNameByValue(value: string): string | undefined {
    const enumName = Object.keys(StripeSubscriptionStatus).find(key => StripeSubscriptionStatus[key as keyof typeof StripeSubscriptionStatus] === value);
    return enumName;
  }
}
