import { AfterViewInit, Component } from '@angular/core';
import { EconomicCalendarComponent } from './economic-calendar/economic-calendar.component';
import { NewsComponent } from './news/news.component';
import { GreedFearIndexComponent } from './greed-fear-index/greed-fear-index.component';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrl: './market.component.scss'
})
export class MarketComponent implements AfterViewInit {
  active = 0

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  contentComponent: any = NewsComponent;

  onTabSelected(index: number) {
    switch (index) {
      case 0:
        this.contentComponent = NewsComponent;
        console.log('Selected tab index is 0');
        // Add your logic for tab index 0
        break;
      case 1:
        this.contentComponent = EconomicCalendarComponent;
        console.log('Selected tab index is 1');
        // Add your logic for tab index 1
        break;
      case 2:
        this.contentComponent = GreedFearIndexComponent;
        console.log('Selected tab index is 2');
        // Add your logic for tab index 2
        break;
      default:
        console.log('Selected tab index:', index);
        // Default case or additional cases can be added as needed
        break;
    }
  }
}
