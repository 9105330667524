import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrl: './nav-list.component.scss'
})
export class NavListComponent {
  @Input() cizeexServices: { name: string, route: string }[] = [];
  @Output() hideDropDownEvent = new EventEmitter<string>();
  constructor(private router: Router) { }

  handleClick() {
    this.hideDropDownEvent.emit("dropdown-default");
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }
}
