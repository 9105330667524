import { Component, Input } from '@angular/core';
import { Stripe } from 'src/app/models/stripe.model';

@Component({
  selector: 'app-stripe-pricing-table',
  templateUrl: './stripe-pricing-table.component.html',
  styleUrl: './stripe-pricing-table.component.scss'
})
export class StripePricingTableComponent {
  @Input() stripeInfo: Stripe;
}
