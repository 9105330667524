import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { Roles } from 'src/app/enums/roles.enum';

@Component({
  selector: 'app-role-selection',
  templateUrl: './role-selection.component.html',
  styleUrls: ['./role-selection.component.scss']
})
export class RoleSelectionComponent implements OnInit {
  // Inputs and Outputs
  @Input() role: number;
  @Output() nextStep = new EventEmitter<string>();

  // Public properties
  selectedRole: string;

  ngOnInit(): void {
    this.selectedRole = Roles[this.role];
  }

  onItemClicked(value: string): void {
    this.selectedRole = Roles[value];
  }

  onNext(): void {
    if (!this.selectedRole) {
      return;
    }

    this.nextStep.emit(this.selectedRole);
  }
}
