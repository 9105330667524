import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Plans } from 'src/app/enums/plans.enum';
import { Roles } from 'src/app/enums/roles.enum';
import { User, UserMapper } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';
import { jwtDecode } from "jwt-decode";
import { BannerService } from '../banner/banner.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
   // Public and Private properties
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private currentUserInfoSubject: BehaviorSubject<any>;
  public currentUserInfo: Observable<any>;
  private REFRESH_TOKEN = 'refresh_token';
  private ACCESS_TOKEN = 'access_token';
  private apiUrl = environment.apiUrl;
  
  constructor(
    private router: Router,
    private http: HttpClient,
    private bannerService: BannerService,
  ) {
    this.setSubjects();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public set currentUserValue(value) {
    this.currentUserSubject.next(value);
  }

  public get currentUserInfoValue(): User {
    return this.currentUserInfoSubject.value;
  }

  public set currentUserInfoValue(value) {
    this.currentUserInfoSubject.next(value);
  }

  register(userForm: User) {
    const mappedData: UserMapper = {
      agreed_to_terms: userForm.agreedToTerms,
      email: userForm.email,
      password: userForm.password,
      current_subscription_id: userForm.plan,
      role_id: userForm.role,
      username: userForm.username
    };
    return this.http.post<{ token: string; refresh_token: string; }>(`${this.apiUrl}auth/register`, mappedData)
      .pipe(
        map(response => {
          return this.fetchCurrentUser(response);
        }),
        catchError(error => {
          // Handle the error response
          let errorMessage = 'Registration failed. Please try again.';

          if (error.status === 400) {
            // Handle specific error if needed
            errorMessage = 'Username or email already exists.';
          }
          // Optionally log error for debugging
          console.error('Registration error:', error);
          this.bannerService.showBanner(errorMessage, 'error', true);
          return throwError(errorMessage);
        })
      );
  }

  login(userForm: any) {
    return this.http.post<any>(`${this.apiUrl}auth/login`, userForm)
      .pipe(
        map(response => {
          return this.fetchCurrentUser(response);
        }),
        catchError(error => {
          // Handle the error response
          let errorMessage = 'Login failed. Please check your credentials and try again.';

          if (error.status === 401) {
            errorMessage = 'Invalid username or password.';
          }

          // Optionally log error for debugging
          console.error('Login error:', error);
          this.bannerService.showBanner(errorMessage, 'error', true);
          return throwError(errorMessage);
        })
      );
  }

  logout() {
    // Remove user from local storage and set current user to null
    localStorage.removeItem(this.ACCESS_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    this.currentUserSubject.next(null);
    this.currentUserSubject.next(null);
    this.currentUserInfoSubject.next(null);
    this.router.navigate(['/en']);
  }

  getToken(): string {
    return this.currentUserValue?.token;
  }

  loadUserFromToken() {
    const token = this.getToken();
    if (token) {
      return this.decodeToken(token);
    }
  }

  isAuthorized(requiredRole?: string, requiredSubscription?: string): boolean {
    const user = this.loadUserFromToken();
    const hasRequiredRole = requiredRole ? Roles[user.role] === requiredRole : true;
    const hasRequiredSubscription = requiredSubscription ? Plans[user.plan] === requiredSubscription : true;
    return hasRequiredRole && hasRequiredSubscription;
  }

  refreshToken(): Observable<string> {
    const refreshToken = localStorage.getItem(this.REFRESH_TOKEN);

    if (!refreshToken) {
      const errorMessage = "Refresh token is missing";
      this.bannerService.showBanner(errorMessage, 'error', false);
      // Handle the case where there is no refresh token available
      return throwError(() => new Error(errorMessage));
    }

    return this.http.post<any>(`${this.apiUrl}auth/refresh`, { refresh_token: refreshToken })
      .pipe(
        map(response => {
          if (response && response.token) {
            // Update the access token only
            localStorage.setItem(this.ACCESS_TOKEN, response.token);

            // Optionally, update the current user subject with the new token info
            this.currentUserSubject.next({ token: response.token });

            // Optionally: Update current user info if the access token contains user data
            this.currentUserInfoSubject.next(this.decodeToken(response.token));

            return response.token;
          } else {
            // Handle the case where the response does not contain a token
            throw new Error('No token received');
          }
        }),
        catchError(error => {
          const errorMessage = "Token refresh error";
          // Handle HTTP errors or token refresh errors
          console.error(errorMessage, error);
          return throwError(() => new Error(errorMessage));
        })
      );
  }

  private fetchCurrentUser(response: { token: string; refresh_token: string; }) {
    if (response && response.token && response.refresh_token) {
      localStorage.setItem(this.ACCESS_TOKEN, response.token);
      localStorage.setItem(this.REFRESH_TOKEN, response.refresh_token);
      this.currentUserSubject.next({ token: response.token });
      this.currentUserInfoSubject.next(this.decodeToken(response.refresh_token));
    }
    return response;
  }

  private decodeToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  private setSubjects(): void {
    const refreshToken = localStorage.getItem(this.REFRESH_TOKEN);
    this.currentUserSubject = new BehaviorSubject<any>(refreshToken);
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUserInfoSubject = new BehaviorSubject<any>(this.decodeToken(refreshToken));
    this.currentUserInfo = this.currentUserInfoSubject.asObservable();
  }

  // loginWithGoogle() {
  //   window.location.href = 'http://localhost:5000/login/google';
  // }

  // loginWithGithub() {
  //   window.location.href = 'http://localhost:5000/login/github';
  // }

  // getUserInfo() {
  //   return this.http.get('http://localhost:5000/user_info');
  // }
}
