import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { NewsEvent } from 'src/app/components/market/interface/news-event.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getStockNews() {
    return this.http.get<NewsEvent[]>(`${this.baseUrl}stock_news`).pipe(
      catchError(error => {
        throw 'Error in retrieving economic calendar data: ' + error;
      })
    );
  }

  getMarketNews() {
    return this.http.get<NewsEvent[]>(`${this.baseUrl}market_news`).pipe(
      catchError(error => {
        throw 'Error in retrieving economic calendar data: ' + error;
      })
    );
  }

  getEtfNews() {
    return this.http.get<NewsEvent[]>(`${this.baseUrl}etf_news`).pipe(
      catchError(error => {
        throw 'Error in retrieving economic calendar data: ' + error;
      })
    );
  }

}
