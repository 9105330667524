import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { Plans } from 'src/app/enums/plans.enum';
import { Roles } from 'src/app/enums/roles.enum';

@Component({
  selector: 'app-plan-selection',
  templateUrl: './plan-selection.component.html',
  styleUrls: ['./plan-selection.component.scss']
})
export class PlanSelectionComponent implements OnInit {
  // Inputs and Outputs
  @Input() selectedRole: number;
  @Output() nextStep = new EventEmitter<string>();

  // Public properties
  plan: number = Plans.Starter;
  selectedPlan: string = Plans[this.plan];
  skipPlan: Plans;
 
  ngOnInit(): void {
    const currentUserRole = +this.selectedRole;
    if (currentUserRole === +Roles.Coder) {
      this.skipPlan = +Plans.Starter;
      this.plan = +Plans.Basic;
      this.selectedPlan = Plans[this.plan];
    }
  }
 
  onItemClicked(value: string): void {
    this.selectedPlan = Plans[value];
  }

  onNext(): void {
    if (!this.selectedPlan) {
      return;
    }
    this.nextStep.emit(this.selectedPlan);
  }
}
