import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from 'src/app/services/loading-service/loading.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
})
export class PaymentSuccessComponent {
  private operationId: string | null = null;
  constructor(
    private stripeService: StripeService,
    private loadingService: LoadingService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    const sessionId = this.route.snapshot.queryParamMap.get('session_id');
    if (sessionId) {
      if (this.operationId) {
        // Prevent multiple operations with the same ID
        return;
      }
      this.loadingService.showSection();
      this.operationId = sessionId; // Set operation ID to prevent duplicates
      this.onPaymentSuccess(sessionId);
    } else {
      console.log("No session ID provided. Please try again.");
    }
  }

  onPaymentSuccess(sessionId: string) {
    this.stripeService.handlePaymentSuccess(sessionId)
      .then(() => {
        // Optionally handle additional logic after payment success
        console.log('Payment processed successfully.');
      })
      .catch(error => {
        // Handle any errors that were caught by the service
        console.error('Error handling payment success:', error);
      });
  }
}