import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { routes } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  language: "es" | "en";

  constructor(
    public translateService: TranslateService,
    private location: Location,
    private route: ActivatedRoute
  ) { }

  initLanguage() {
    this.translateService.addLangs(["en", "es", "iz"]);
    let language = navigator.language || (navigator as any).userLanguage;
    language = language.split("-").includes("es") ? "es" : language.split("-").includes("iz") ? "iz" : "en";
    this.translateService.setDefaultLang(language);
    // Change the URL without navigate
    if (!this.checkRoute()) this.location.go(language);
    this.language = language;

  }

  changeLanguage(language) {
    this.translateService.setDefaultLang(language)
    if (!this.checkRoute()) this.location.go(language);
    this.language = language
  }

  checkRoute(): boolean {
    const currentPath = this.location.path();
    for (const route of routes) {
      if (`/${route.path}` === currentPath.split('?')[0]) {
        return (route.data && route.data.dontRedirect);
      }
    }
    return false;
  }

  avoidTranslate(): boolean {
    const currentPath = this.location.path();
    for (const route of routes) {
      if (`/${route.path}` === currentPath.split('?')[0]) {
        return (route.data && route.data.dontTranslate);
      }
    }
    return false;
  }
}
