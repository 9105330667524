// prompt-ui.component.ts
import { Component, Input, AfterViewChecked, ElementRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-prompt-ui',
  templateUrl: './prompt-ui.component.html',
  styleUrls: ['./prompt-ui.component.scss'],
})
export class PromptUiComponent implements OnChanges, AfterViewChecked {
  @Input() isDisabled: boolean = true;
  @Input() aiReaction: string = '';
  @ViewChild('messagesContainer') private messagesContainer!: ElementRef;
  @ViewChild('messageText') messageText: ElementRef;

  messages: { text: string }[] = [];
  userInput: string = '';

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.aiReaction && changes.aiReaction.currentValue && changes.aiReaction.currentValue !== '') {
      // Handle changes to aiReaction and isDisabled if needed
      this.messages.push({ text: this.aiReaction });
    }
  }

  ngAfterViewChecked() {
    if (this.messages.length > 1)
      this.scrollToBottom();
  }

  sendMessage() {
    if (this.userInput.trim()) {
      this.messages.push({ text: this.userInput.trim() });
      this.userInput = '';
    }
  }

  likeMessage(message: { text: string }) {
    console.log('Liked message:', message.text);
  }

  copyMessage() {
    navigator.clipboard.writeText(this.messageText.nativeElement.textContent +
      "\nCheckout https://www.cizeex.com for more info.").then(() => {
      }).catch((err) => {
        console.error('Error copying text: ', err);
      });
  }

  private scrollToBottom(): void {
    try {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error('Could not scroll to bottom:', err);
    }
  }
}
