<section class="section terms-code-collaborate" id='terms-code-collaborate'>
    <div class="container">
        <div class="section-box">
            <div class="terms-code-collaborate-title" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>01.</span> {{"PrivacyPolicy.Cizeex.Title" | translate}}
                </h3>
                <p>{{ "PrivacyPolicy.Cizeex.EffectiveDate" | translate}}</p>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000"
                *ngFor="let item of 'PrivacyPolicy.Cizeex.Sections'| translate; let i = index">
                <h5 class="terms-code-collaborate-time"><span class="underline">{{item.Title}}</span></h5>
                <p *ngFor="let desc of item.Description; let i = index" class="points-description"
                    [innerHTML]="desc | translate">{{desc}}</p>
            </div>
        </div>
    </div>
</section>