import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading-service/loading.service';
import { Roles } from 'src/app/enums/roles.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  Roles = Roles;
  loginForm: FormGroup;
  returnUrl: string;
  error: string;
  showForgotPassword = false;
  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    if (this.authService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/en';
  }

  onSubmit() {
    this.loadingService.showSection();
    this.authService.login(this.loginForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.loadingService.hideSection();
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.loadingService.hideSection();
          this.error = error;
        });
  }
  responsiveMenuVisible: Boolean = false;
  scroll(el) {
    if (document.getElementById(el)) {
      document.getElementById(el).scrollIntoView({ behavior: 'smooth' });
    } else {
      this.router.navigate(['/en']).then(() => document.getElementById(el).scrollIntoView({ behavior: 'smooth' }));
    }
    this.responsiveMenuVisible = false;
  }

  logout() {
    this.authService.logout();
  }

  loginWithGoogle() {
    //this.authService.loginWithGoogle();
  }

  loginWithGithub() {
    //this.authService.loginWithGithub();
  }

}