import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading-service/loading.service';
import { Roles } from 'src/app/enums/roles.enum';
import { BannerService } from 'src/app/services/banner/banner.service';
import { Plans } from 'src/app/enums/plans.enum';
import { validEmailDomain } from 'src/app/validators/valid-email-domain.validator';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  userDetailsForm: FormGroup;
  @Output() nextStep = new EventEmitter<any>();
  @Input() isCodeCollaborate: boolean = false;

  constructor(private formBuilder: FormBuilder, private router: Router,
    private route: ActivatedRoute, private authService: AuthService,
    private loadingService: LoadingService, private bannerService: BannerService) { }
  returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/en';
  error: string;
  ngOnInit(): void {
    this.userDetailsForm = this.formBuilder.group({
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, validEmailDomain()]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      agreedToTerms: [false, Validators.requiredTrue],
      role: [Roles.Cizeex],
      plan: [Plans.Starter]
    });
  }

  get email() {
    return this.userDetailsForm.get('email');
  }

  onSubmit(): void {
    this.error = null;
    if (this.userDetailsForm.invalid) {
      return;
    }

    if (this.userDetailsForm.get('password').value !== this.userDetailsForm.get('confirmPassword').value) {
      this.error = "Passwords do not match!";
      return;
    }

    if (!this.userDetailsForm.get('agreedToTerms').value) {
      this.error = 'You must agree to the terms';
      return;
    }

    if (this.isCodeCollaborate) {
      this.nextStep.emit(this.userDetailsForm.value);
    }
    else {
      this.loadingService.showSection();
      this.authService.register(this.userDetailsForm.value)
        .pipe(first())
        .subscribe(
          data => {
            this.loadingService.hideSection();
            this.bannerService.showBanner('You have registered successfully!', 'success', true);
            this.router.navigate([this.returnUrl]);
          },
          error => {
            this.loadingService.hideSection();
            this.error = error;
          });
    }
  }
}