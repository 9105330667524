export enum StripeSubscriptionStatus {
    Newbie = 'newbie',                  // No Subscription yet
    Active = 'active',                  // Subscription is active
    All = 'all',                        // All subscription statuses
    Canceled = 'canceled',              // Subscription has been canceled
    Ended = 'ended',                    // Subscription has ended
    Incomplete = 'incomplete',          // Subscription is incomplete (e.g., payment failed)
    IncompleteExpired = 'incomplete_expired',  // Incomplete subscription that has expired
    PastDue = 'past_due',               // Payment for the subscription is past due
    Paused = 'paused',                  // Subscription has been paused
    Trialing = 'trialing',              // Subscription is in a trial period
    Unpaid = 'unpaid'                   // Subscription is unpaid (e.g., due to a failed charge)
  }