import { Component, OnInit, ViewChild } from '@angular/core';
import { GreedFearIndexService } from 'src/app/services/greed-fear-index/greed-fear-index.service';
import { LoadingService } from 'src/app/services/loading-service/loading.service';
import { PromptUiComponent } from '../../general/prompt-ui/prompt-ui.component';
import { GreedFearIndex } from '../interface/greed-fear-index.model';
import { MarketReactionService } from 'src/app/services/market-reaction/market-reaction.service';

@Component({
  selector: 'app-greed-fear-index',
  templateUrl: './greed-fear-index.component.html',
  styleUrl: './greed-fear-index.component.scss'
})
export class GreedFearIndexComponent implements OnInit {
  @ViewChild('promptUi') promptUi: PromptUiComponent;
  hasData = false;
  hasIndexData: boolean = false;
  data: any;
  aiReaction: string | Promise<string>;
  
  constructor(private greedFearIndexService: GreedFearIndexService, 
    private loadingService: LoadingService,
    private marketReactionService: MarketReactionService) { }

  ngOnInit(): void {
    this.fetchData();
  }

  isPromptDisabled: boolean = true; // Change this based on user subscription status

  // Example method to toggle prompt disabled state
  togglePromptState() {
    this.isPromptDisabled = !this.isPromptDisabled;
  }

  isRadarGraph = true;

  toggleGraphType() {
    this.isRadarGraph = !this.isRadarGraph;
  }

  private fetchData(): void {
    this.hasIndexData = false;
    this.loadingService.show();
    this.greedFearIndexService.getGreedFearIndex().subscribe(
      data => {
        this.data = data;
        this.getAnalysis(this.data);
        this.hasIndexData = true;
        this.loadingService.hide();
      },
      error => {
        console.error('Error fetching news data:', error);
        this.loadingService.hide();
        this.hasIndexData = false;
      }
    );
  }

  getAnalysis(data: GreedFearIndex) {
    this.hasData = false;
    this.loadingService.showSection();
    this.marketReactionService.getGreedFearIndexReaction(data)
      .subscribe(
        response => {
          this.aiReaction = this.marketReactionService.convertAiToHtml(response.toString());
          this.hasData = true;
          this.loadingService.hideSection();
        },
        error => {
          console.error('Error fetching greed fear index reaction:', error);
          this.loadingService.hideSection();
        }
      );
  }
}