export const environment = {
  production: false,
  staging: true,
  url: 'https://stg.cizeex.com/',
  apiUrl: 'https://stg-api.cizeex.com/',
  authApiUrl: 'https://stg-auth.cizeex.com/',
  ghOrgName:'cizeex',
  stripePublicKey: 'pk_test_51Q7schCeweEp7MXgxloN7FQfFzOgkjihqHSbFE32aKNRiDF9ROljUea76rW2gxO96ra04P4nR1TAvJ8rl9ff3npG00nFuupj2n',
  stripePublicKeyOld: 'pk_test_51PockLCfeuZakdCowPC7Tr8Me8VYNyfGIaDvG0Dt08oN1o6HD4UAM0n3iMqOm5UIu6vqGTVZXkM2W75oG5b0gbV500uupu7U7J',
  priceTableId: 'prctbl_1PqUfXCfeuZakdCoTJFFPkzG',
  priceTableId_test: 'prctbl_1Pq52iCfeuZakdCoSMOk0rfD',
  githubClientId: 'Ov23liBNuSdbulMCBJkR',
  trackAnalyticID: 'G-XL707F1P55'
};
