<div *ngIf="bannerMessage" [ngClass]="bannerMessage.type" class="banner-container" data-aos="fade-up"
  data-aos-duration="2000">
  <span class="banner-message">{{ bannerMessage.message }}</span>
  <button class="close-btn" (click)="closeBanner()">&#10005;</button>
</div>


<!-- <app-banner 
  message="Welcome to our website!" 
  type="success"
  [autoDismiss]="true"
  [dismissTime]="3000">
</app-banner>

<app-banner 
  message="Error: Something went wrong!" 
  type="error"
  [autoDismiss]="false">
</app-banner> -->