// loading.component.ts

import { Component } from '@angular/core';
import { LoadingService } from 'src/app/services/loading-service/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent {
  loading$ = this.loadingService.loading$;
  loadingSection$ = this.loadingService.loadingSection$;

  constructor(private loadingService: LoadingService) { }
}