<section class="section" id="market">
    <div class="container">
        <div class="section-box">
            <div class="cizeex-plus-title" data-aos="fade-up" data-aos-duration="1000">
                <h3 class="e-font section-title">
                    <span class="code-font n-section-title">02.</span>
                    {{ "Market.Title" | translate }}
                </h3>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000">
                <app-tabs [tabsInfo]="'Market.Tabs' | translate" [contentComponent]="contentComponent"
                    (tabSelected)="onTabSelected($event)">
                </app-tabs>
            </div>
        </div>
    </div>
</section>