import { Component, OnInit } from '@angular/core';
import { BannerMessage, BannerService } from 'src/app/services/banner/banner.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  bannerMessage: BannerMessage | null = null;

  constructor(private bannerService: BannerService) { }

  ngOnInit(): void {
    this.bannerService.bannerMessage$.subscribe((message) => {
      this.bannerMessage = message;
    });
  }

  closeBanner(): void {
    this.bannerService.clearBanner();
  }
}
