import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { parse, format, isValid, subMinutes, subHours } from 'date-fns';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnChanges {
  @Input() data: any[] = [];
  @Input() dateKey: string = 'date';
  @Input() datetimeKey: string = 'datetime';
  @Input() excludeHeaders: string[] = [];
  @Input() orderHeaders: string[] = [];
  @Input() aiReaction: string = '';
  @Output() rowClicked = new EventEmitter<any>();

  groupedEvents: any[] = [];
  headers: string[] = [];
  aiReactionInputValue: string;
  expandedEvent: any;
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.groupDataByDate(this.data);
      this.extractHeaders(this.data);
    }
    if (changes.aiReaction) {
      // Handle changes to aiReaction if needed
      this.aiReactionInputValue = this.aiReaction;
    }
  }

  parseRelativeTime(timeString) {
    const now = new Date();

    if (timeString.includes('min')) {
      const minutes = parseInt(timeString.split(' ')[0], 10);
      return subMinutes(now, minutes);
    } else if (timeString.includes('hour')) {
      const hours = parseInt(timeString.split(' ')[0], 10);
      return subHours(now, hours);
    } else if (timeString.includes('AM') || timeString.includes('PM')) {
      return parse(timeString, 'hh:mma', now);
    } else {
      return parse(timeString, 'MMM-dd', now);
    }
  }

  groupDataByDate(events) {
    const eventsByDate = {};

    events.forEach(event => {
      let dateTime;
      const dateStr = event[this.dateKey];

      // First, try to parse relative time formats
      dateTime = this.parseRelativeTime(dateStr);

      // If dateTime is still invalid, try other date formats
      if (!isValid(dateTime)) {
        const formats = ['yyyy-MM-dd', 'MMM-dd', 'hh:mma', 'dd/MM/yyyy'];
        for (let formatStr of formats) {
          dateTime = parse(dateStr, formatStr, new Date());
          if (isValid(dateTime)) break;
        }
      }

      // Handle invalid date
      if (!isValid(dateTime)) {
        console.error(`Invalid date format: ${dateStr}`);
        return;
      }

      // Create a date string without time
      const date = format(dateTime, 'yyyy-MM-dd');

      if (!eventsByDate[date]) {
        eventsByDate[date] = [];
      }
      eventsByDate[date].push(event);
    });

    this.groupedEvents = Object.keys(eventsByDate).map(date => ({
      date,
      events: eventsByDate[date].sort((a, b) => new Date(a[this.datetimeKey]).getTime() - new Date(b[this.datetimeKey]).getTime())
    }));
  }

  extractHeaders(data: any[]) {
    if (data.length > 0) {
      // Get all keys from the first data item
      const allHeaders = Object.keys(data[0]);

      // Filter headers based on exclusions
      const filteredHeaders = allHeaders.filter(header => !this.excludeHeaders.includes(header));

      // Reorder filtered headers based on objReorder
      if (this.orderHeaders?.length === 0) {
        this.headers = filteredHeaders;
      }
      else {
        this.headers = this.orderHeaders.filter(header => filteredHeaders.includes(header));
      }
    }
  }

  toggleExpand(event: any) {
    this.aiReactionInputValue = null;
    if (this.expandedEvent && this.expandedEvent !== event) {
      this.expandedEvent.isExpanded = false;  // Collapse the previously expanded event
    }
    event.isExpanded = !event.isExpanded;
    this.expandedEvent = event.isExpanded ? event : null;
  }

  onRowClick(event: any) {
    this.rowClicked.emit(event);
  }
}
