// subscription-updated.component.ts
import { Component, OnInit } from '@angular/core';
import { Plans } from 'src/app/enums/plans.enum';
import { Roles } from 'src/app/enums/roles.enum';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-subscription-updated',
  templateUrl: './subscription-updated.component.html',
  styleUrls: ['./subscription-updated.component.scss']
})
export class SubscriptionUpdatedComponent implements OnInit {
  planName: string;
  Roles = Roles;
  private _auth: AuthService;

  constructor(private authService: AuthService) {
    this._auth = authService;
  }

  get auth(): AuthService {
    return this._auth;
  }

  ngOnInit(): void {
    this.planName = Plans[this._auth.currentUserInfoValue.plan];
  }
}