<section class="section terms-code-collaborate" id='terms-code-collaborate'>
    <div class="container">
        <div class="section-box">
            <div class="terms-code-collaborate-title" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>01.</span> {{"TermsOfService.CodeCollaborate.Title" | translate}}
                </h3>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000"
                *ngFor="let item of 'TermsOfService.CodeCollaborate.Sections'| translate; let i = index">
                <h5 class="terms-code-collaborate-time">{{item.Title}}</h5>
                <p *ngFor="let desc of item.Description; let i = index" class="points-description"
                    [innerHTML]="desc | translate">{{desc}}</p>
            </div>
            <div class="terms-code-collaborate-title padding-top-15" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>02.</span> {{"NDA.CodeCollaborate.Title" | translate}}
                </h3>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000"
                *ngFor="let item of 'NDA.CodeCollaborate.Sections'| translate; let i = index">
                <h5 class="terms-code-collaborate-time">{{item.Title}}</h5>
                <p *ngFor="let desc of item.Description; let i = index" class="points-description"
                    [innerHTML]="desc | translate">{{desc}}</p>
            </div>
            <div class="terms-code-collaborate-title padding-top-15" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>02.</span> {{"CLA.CodeCollaborate.Title" | translate}}
                </h3>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000"
                *ngFor="let item of 'CLA.CodeCollaborate.Sections'| translate; let i = index">
                <h5 class="terms-code-collaborate-time">{{item.Title}}</h5>
                <p *ngFor="let desc of item.Description; let i = index" class="points-description"
                    [innerHTML]="desc | translate">{{desc}}</p>
            </div>
            <div>
                <p data-aos="fade-up" data-aos-duration="1000">{{"TermsOfService.CodeCollaborate.ByRegistering" |
                    translate}}</p>
            </div>
        </div>
    </div>
</section>