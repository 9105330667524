import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationComponent } from './registration/registration.component';
import { LoginComponent } from './login/login.component';
import { PaymentSuccessComponent } from './subscription-pages/payment-success/payment-success.component';
import { SubscriptionUpdatedComponent } from './subscription-pages/subscription-updated/subscription-updated.component';
import { SubscriptionCanceledComponent } from './subscription-pages/subscription-canceled/subscription-canceled.component';
import { SubscriptionPausedComponent } from './subscription-pages/subscription-paused/subscription-paused.component';
import { SubscriptionResumedComponent } from './subscription-pages/subscription-resumed/subscription-resumed.component';

const routes: Routes = [
  { path: 'registration', component: RegistrationComponent , data: { dontRedirect: true }},
  { path: 'login', component: LoginComponent , data: { dontRedirect: true }},
  { path: 'payment-success', component: PaymentSuccessComponent },
  { path: 'subscription-updated', component: SubscriptionUpdatedComponent , data: { dontRedirect: true }},
  { path: 'subscription-canceled', component: SubscriptionCanceledComponent , data: { dontRedirect: true }},
  { path: 'subscription-paused', component: SubscriptionPausedComponent , data: { dontRedirect: true }},
  { path: 'subscription-resumed', component: SubscriptionResumedComponent , data: { dontRedirect: true }}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }