import { Component } from '@angular/core';
import { Roles } from 'src/app/enums/roles.enum';

@Component({
  selector: 'app-subscription-paused',
  templateUrl: './subscription-paused.component.html',
  styleUrl: './subscription-paused.component.scss'
})
export class SubscriptionPausedComponent {
  Roles = Roles;
}
