import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { marked } from 'marked';
import { environment } from 'src/environments/environment';
import { GreedFearIndex } from 'src/app/components/market/interface/greed-fear-index.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MarketReactionService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getMarketReaction(eventName: string, eventDate: string, forecast: string, previous: string): Observable<any> {
    const body = { eventname: eventName, eventdate: eventDate, forecast: forecast, previous: previous };
    return this.http.post<any>(`${this.apiUrl}market_reaction`, body);
  }

  getNewsReaction(headline: string, link: string, time: string, ticker: string, resource: string): Observable<any> {
    const body = { headline: headline, link: link, time: time, ticker: ticker, resource: resource };
    return this.http.post<any>(`${this.apiUrl}news_reaction`, body);
  }

  getGreedFearIndexReaction(data: GreedFearIndex): Observable<any> {
    const body = { vixValue: data.vixValue, 
      stockPriceStrength: data.stockPriceStrength,
      marketMomentum: data.marketMomentum, 
      greedFearIndex: data.greedFearIndex };
    return this.http.post<any>(`${this.apiUrl}greed-fear-index-reaction`, body).pipe(map(result => result.response));
  };

  convertAiToHtml(text: string): string | Promise<string> {
    return marked(text);
  }
}
