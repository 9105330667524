<div class="table-responsive" *ngIf="groupedEvents">
  <table class="table table-hover">
    <ng-container *ngFor="let dateGroup of groupedEvents">
      <thead class="table-header">
        <tr style="white-space: nowrap">
          <th colspan="{{ headers.length + 1 }}" style="text-align: left;">{{ dateGroup.date }}</th>
          <ng-container *ngFor="let header of headers">
            <th>{{ header | pascalCase }}</th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let event of dateGroup.events">
          <tr (click)="onRowClick(event); toggleExpand(event)" [class.expanded]="event.isExpanded">
            <td>
              <span style="padding-right: 5px;" [class]="event.isExpanded ? 'arrow-up' : 'arrow-down'"></span>
            </td>
            <ng-container *ngFor="let header of headers">
              <td style="height: 30px;" [title]="event[header]">
                <ng-container [ngSwitch]="header">
                  <span *ngSwitchDefault>{{ event[header] | truncate }}</span>
                  <span *ngSwitchCase="'time'" [ngClass]="{'calendar-date-time': event[header] !== null}">{{
                    event[header] }}</span>
                  <span *ngSwitchCase="'link'"> <a [href]="event[header]" target="_blank">{{ event[header] |
                      truncate:headers.length > 3 ? 30 : 60
                      }}</a></span>
                </ng-container>
              </td>
            </ng-container>
          </tr>
          <tr class="expanded-tr" *ngIf="event.isExpanded">
            <td [attr.colspan]="headers.length + 1">
              <div class="reaction-ai">
                <app-loading></app-loading>
                <app-prompt-ui [aiReaction]="aiReactionInputValue"></app-prompt-ui>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </ng-container>
  </table>
</div>
<div *ngIf="groupedEvents?.length === 0" style="min-height: 500px;">
  <p class="no-grid-data">No data found</p>
</div>