import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements AfterViewInit {
  constructor(private http: HttpClient) { }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
}