<section class="section" id="role">
    <div class="container">
        <div class="section-box" data-aos="flip-up" data-aos-duration="1000">
            <div class="cizeex-plus-title">
                <h3 class="e-font section-title">
                    <span class="code-font n-section-title">05.</span>
                    {{ "Header.Item6" | translate }}
                </h3>
            </div>
            <div class="role-container">
                <!-- role header -->
                <div class="role-header">
                    <h2>Select Your Role</h2>
                    <p>Pick Cizeex for other services than CodeCollabarate</p>
                </div>
                <app-multiselect [items]="'RoleInfo.Roles' | translate" [itemTitle]="'Roles'" [selectedItem]="role"
                    (itemClicked)="onItemClicked($event)"></app-multiselect>
                <button (click)="onNext()" [disabled]="!this.selectedRole">
                    <a class="btn btn-1">
                        <svg>
                            <rect x="0" y="0" fill="none" width="100%" height="100%" />
                        </svg>
                        Next
                    </a>
                </button>
                <p class="question-link">have an question? <a href="mailto:ccizeex.com@gmail.com" target="_blank">Click here</a></p>
            </div>
        </div>
    </div>
</section>