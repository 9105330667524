import { inject } from '@angular/core';
import { CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { BannerService } from '../services/banner/banner.service';
import { Roles } from '../enums/roles.enum';
import { Plans } from '../enums/plans.enum';

/**
 * Guard to protect routes based on user authentication and authorization.
 */
export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const bannerService = inject(BannerService);
  // Retrieve the current user from the authentication token
  const user = authService.currentUserInfoValue;

  // If no user is logged in, redirect to login page
  if (!user) {
    router.navigate(['/login']);
    return false;
  }
  // Extract the required role and subscription plan from the route data
  const requiredRole = route.data['requiredRole'];
  const requiredSubscription = route.data['requiredSubscription'];

  // Check if the user has the required role and subscription
  if (isAuthorized(user, requiredRole, requiredSubscription)) {
    return true; // Access granted
  } else {
    // Show an error banner and redirect to the 'Not Authorized' page
    bannerService.showBanner("Access Denied: Insufficient Permissions", "warning", true);
    router.navigate(['/not-authorized']);
    return false; // Access denied
  }
};

/**
 * Helper function to check if the user is authorized based on role and subscription.
 * 
 * @param user - The current user object
 * @param requiredRole - The required role for the route (optional)
 * @param requiredSubscription - The required subscription plan for the route (optional)
 * @returns boolean - Whether the user is authorized
 */
function isAuthorized(user: any, requiredRoles?: string[], requiredSubscription?: string[]): boolean {

  if (!requiredRoles && !requiredSubscription) {
    return false;
  }
  // Check if the user's role matches any of the required roles (if any)
  const hasRequiredRole = requiredRoles
    ? requiredRoles.some(role => Roles[user.role] === role)
    : true;

  // Check if the user's plan does not match any of the excluded subscriptions (if any)
  const hasValidSubscription = requiredSubscription
    ? !requiredSubscription.some(sub => Plans[user.plan] === sub)
    : true;

  return hasRequiredRole && hasValidSubscription;
}
